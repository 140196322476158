<template>
  <div
    class="
      d-flex
      flex-column
      align-items-center
      justify-content-between
      text-center
      p-3
    "
  >
    <h5 v-if="surveyTitle">{{ surveyTitle }}</h5>
    <!-- <vue-qr
      :logoSrc="logoSrc"
      :text="isSrc"
      :size="size"
      :callback="qrCallback"
      :qid="`${surveyId}`"
    ></vue-qr> -->
    <vue-qr
      :text="isSrc"
      :size="size"
      :callback="qrCallback"
      :qid="`${surveyId}`"
    ></vue-qr>
    <div class="d-flex flex-row justify-content-space">
      <input
        v-show="false"
        type="text"
        v-model="isSrc"
        id="inputTextQrCode"
        disabled
        class="mr-2"
      />

      <b-button size="sm" variant="primary" class="mr-2" @click="copyLink"
        >คัดลอก</b-button
      >

      <b-button
        v-if="srcImgBase64"
        size="sm"
        variant="primary"
        @click="downLoadPdfQrcode"
      >
        ดาวน์โหลด</b-button
      >

      <!-- <b-button v-if="srcImgBase64" size="sm" variant="primary"
        ><a
          :href="srcImgBase64"
          :download="`${surveyTitle}.png`"
          style="color: white"
        >
          ดาวน์โหลด</a
        ></b-button
      > -->
    </div>
    <div class="p-2">
      <b-button
        v-if="srcImgBase64"
        size="sm"
        variant="primary"
        @click="genPdfQrcode"
      >
        พิมพ์</b-button
      >
    </div>
    <generate-pdf
      ref="pdf"
      :item="{ number: `${surveyTitle}.pdf` }"
      :format="format"
      :enable-download="enableDownload"
      :preview-modal="previewModal"
    ></generate-pdf>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import { generateQrCode } from "../../helpers/template-pdf-transaction";
import GeneratePdf from "../report/GeneratePdf";

export default {
  components: {
    VueQr,
    GeneratePdf,
  },

  props: {
    surveyTitle: String,
    teachingDareId: Number,
    surveyId: Number,
    survey: Object,
    teachingDare: Object,
  },

  data() {
    return {
      logoSrc:
        "https://d10a8qjc50njp0.cloudfront.net/background-certificate/logo.png",
      size: 200,
      srcImgBase64: "",
      format: "",
      enableDownload: false,
      previewModal: true,
    };
  },

  computed: {
    isSrc() {
      return (
        window.location.protocol +
        "//" +
        window.location.host +
        `/survey-question?teachingDareId=${this.teachingDareId}&surveyId=${this.surveyId}`
      );
    },
  },

  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.isSrc);
    },
    qrCallback(dataUrl) {
      this.srcImgBase64 = dataUrl;
      this.$emit("qrUrl", { survey: this.survey, dataUrl });
    },

    async downLoadPdfQrcode() {
      this.enableDownload = true;
      this.previewModal = false;
      let item = {
        surveyTitle: this.surveyTitle,
        srcImgBase64: this.srcImgBase64,
      };
      this.format = await generateQrCode(item, this.teachingDare);

      if (this.$refs.pdf) {
        const { $refs } = this.$refs.pdf;

        $refs.html2Pdf.generatePdf();
      }
    },

    async genPdfQrcode() {
      this.enableDownload = false;
      this.previewModal = true;
      let item = {
        surveyTitle: this.surveyTitle,
        srcImgBase64: this.srcImgBase64,
      };
      this.format = await generateQrCode(item, this.teachingDare);

      if (this.$refs.pdf) {
        const { $refs } = this.$refs.pdf;

        $refs.html2Pdf.generatePdf();
      }
    },
  },
};
</script>
