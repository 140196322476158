<template>
  <b-modal
    v-if="item"
    :id="`modal-qr-code__${item.id}`"
    v-model="$show"
    centered
    size="xl"
    :hide-header="true"
    no-close-on-backdrop
    @hidden="resetQrUrls"
  >
    <div v-if="surveys && surveys.length > 0" class="row">
      <base-qr-code
        class="col"
        v-for="isSurvey in surveys"
        :key="`isSurvey_${isSurvey.id}`"
        :teachingDareId="item.id"
        :surveyTitle="isSurvey.survey_title"
        :surveyId="isSurvey.id"
        :survey="isSurvey"
        :teachingDare="teachingDare"
        @qrUrl="qrUrl"
      ></base-qr-code>
    </div>

    <template #modal-footer="{ cancel }">
      <b-button
        size="sm"
        variant="primary"
        @click="downLoadPdfQrcodeAll"
        :disabled="surveys && surveys.length == 0"
      >
        ดาวน์โหลดทั้งหมด
      </b-button>
      <b-button
        size="sm"
        variant="primary"
        @click="genPdfQrcodeAll"
        :disabled="surveys && surveys.length == 0"
      >
        พิมพ์ทั้งหมด
      </b-button>
      <b-button size="sm" variant="dark" @click="cancel"> ปิด </b-button>
    </template>

    <generate-pdf
      :id="`gen_qrcodeAll--${item.id}`"
      :ref="`pdf__${item.id}`"
      :item="{ number: `แบบประเมิน_${item ? item.id : ''}.pdf` }"
      :format="format"
      :enable-download="enableDownload"
      :preview-modal="previewModal"
    ></generate-pdf>
  </b-modal>
</template>

<script>
import BaseQrCode from "../qrcode/BaseQrCode.vue";
import { Survey, TeachingDare } from "../../models";
import { generateQrCodeAll } from "../../helpers/template-pdf-transaction";
import GeneratePdf from "../report/GeneratePdf";

export default {
  components: {
    BaseQrCode,
    GeneratePdf,
  },

  props: {
    show: Boolean,
    item: Object,
  },

  data() {
    return {
      surveys: [],
      qrUrls: [],
      teachingDare: null,
      format: "",
      enableDownload: false,
      previewModal: true,
    };
  },

  watch: {
    item: {
      immediate: true,
      handler: "getSurvey",
    },
  },

  computed: {
    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },
  },

  methods: {
    async getTeachingDare() {
      let { response } = await TeachingDare.api().findOne(this.item.id);
      if (response && response.data) {
        const { data } = response.data;
        this.teachingDare = data;
      }
    },

    async getSurvey() {
      await this.getTeachingDare();
      let { response } = await Survey.api().findAll();
      if (response && response.data) {
        const { data } = response.data;
        this.surveys = data;
      }
    },

    qrUrl(v) {
      this.qrUrls.push(v);
    },

    resetQrUrls() {
      this.qrUrls = [];
      this.surveys = [];
    },

    async downLoadPdfQrcodeAll() {
      this.enableDownload = true;
      this.previewModal = false;
      this.format = await generateQrCodeAll(this.qrUrls, this.teachingDare);

      if (this.format && this.$refs[`pdf__${this.item.id}`]) {
        const { $refs } = this.$refs[`pdf__${this.item.id}`];
        this.$nextTick(() => {
          $refs.html2Pdf.generatePdf();
        });
      }
    },

    async genPdfQrcodeAll() {
      if (this.qrUrls && this.qrUrls.length > 0) {
        this.enableDownload = false;
        this.previewModal = true;
        this.format = await generateQrCodeAll(this.qrUrls, this.teachingDare);

        if (this.format && this.$refs[`pdf__${this.item.id}`]) {
          const { $refs } = this.$refs[`pdf__${this.item.id}`];
          this.$nextTick(() => {
            $refs.html2Pdf.generatePdf();
          });
        }
      }
    },
  },

  model: {
    prop: "show",
    event: "update",
  },
};
</script>
